import Data from './data';
//------ Membres privés ---------
let defaults = {
	ui: {
		moduleID: "body",
		likeBtnClass: '.js-like'
	}
};

export default class {
	constructor(config) {
		this.params = $.extend({}, defaults, config);
		this.onProcess = false;
		this.init();
	}

	init() {
		this.cacheElements();
		this.addUIEvents();
	}

	//mise en cache des differents elements De l'interface dans le scope de moduleID via params.ui
	cacheElements() {
		if (this.params.ui.moduleID) {
			this.$module = $(this.params.ui.moduleID);
			for (var key in this.params.ui) {
				if (this.params.ui.hasOwnProperty(key) && key !== 'moduleID') {
					this['$' + key] = this.$module.find(this.params.ui[key]);
				}
			}
		}
	}

	//ajout des listener sur l'interface
	addUIEvents() {
		this.$likeBtnClass.one('click', (e) => this.likeHandler(e));
	}

	likeHandler(e) {
		e.preventDefault();
		if (!this.onProcess) {
			this.onProcess = true;
			var $target = $(e.currentTarget);
			var $nbVotes = $('.js-like-count');
			if (typeof $target.data('id') !== 'undefined') {
				let idVideo = $target.data('id');
				var d = Data.like(idVideo);
				d.success(function(d) {
					$target.addClass('isDisabled');

					if($nbVotes.text() == "0"){
						$('#first-like').hide();
						$('#first-like-count').show();
					}
					$nbVotes.text(parseInt($nbVotes.text()) + 1);

					this.onProcess = false;
				});
			} else {
				throw 'You have to identify the videoId to like';
			}
		}
	}
}
