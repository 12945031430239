//------ Membres privés ---------
var defaults = {

    ui: {
        moduleID: "#filters",
        filtersForm: '#filters-form'
    }
};

//------ Constructeur ---------
export default class {

    constructor(config) {
        this.params = $.extend({}, defaults, config);
        this.init();
        this.state = {};
    }

    init() {
        this.cacheElements();
        this.addUIEvents();
    }

    //mise en cache des differents elements De l'interface dans le scope de moduleID via params.ui
    cacheElements() {
        if (this.params.ui.moduleID) {
            this.$module = $(this.params.ui.moduleID);
            for (var key in this.params.ui) {
                if (this.params.ui.hasOwnProperty(key) && key !== 'moduleID') {
                    this['$' + key] = this.$module.find(this.params.ui[key]);
                }
            }
        }
    }

    //ajout des listenr sur l'interface
    addUIEvents() {
        var self = this;
        this.$filtersForm.on('change', $.proxy(this.onFiltersChange, this));
    }

    publishState(e) {
        $.publish('filters:change', {
            filters: this.state
        });
    }

    onFiltersChange(e) {
        if ($(e.target).hasClass('isSilencious')) {
            return false;
        }
        var data = this.$filtersForm.serializeArray();

        var formatedData = {};

        for (var i = 0, len = data.length; i < len; i++) {
            formatedData[data[i]["name"]] = formatedData[data[i]["name"]] || [];
            if (data[i]["value"] !== '') {
                formatedData[data[i]["name"]].push(data[i]["value"]);
            }
        }
        this.state = formatedData;
        this.publishState();
    }

}
