import Data from './data';


//------ Membres privés ---------
var defaults = {

    ui: {
        moduleID: "#contactModal"
    }
};

//------ Constructeur ---------
export default class {

    constructor(config) {
        this.params = $.extend({}, defaults, config);
        this.init();
    }

    init() {

        let that = this;
        $('#contactModal').on('open.zf.reveal',function(){
          $('#contactModal form').on('submit', that.onSubmit);
        });

        $('#contactModal').on('closed.zf.reveal',function(){
          $('#contactModal form').off('submit', that.onSubmit);
          $('#contactModal form')[0].reset()
        })

    }

    onSubmit(e){
      e.preventDefault();
      $('#contactModal input[type=submit]').prop('disabled',true);
      let data = $('#contactModal form').serialize();
      Data.sendMessage(data).done(function(){
        $('#contactModal textarea').val('Message envoyé');
        _.delay(function(){
          $('#contactModal').foundation('close');
        },1000);

      })

    }


}
