import Data from './data';

let _i18n = {
	fr: {
		deconnectMsg: 'Connectez-vous pour commenter',
		comTwit: 'Commentez',
		comFB: 'Commentez',
		comSend: 'Commentaire envoyé !',
		comPluriel: 'commentaires'
	},
	en: {
		deconnectMsg: 'login to comment',
		comTwit: "Let's comment",
		comFB: "Let's comment",
		comSend: 'Send !',
		comPluriel: 'comments'

	}
};


export default class {
	constructor(config) {
		let conf = config || {};
		this.autoInit = conf.autoInit || true; // le module doit-il s'auto initialiser (true)		
		this.comFormID = conf.comFormID || '#comments-form';
		this.inProgress = false;
		this.$inputComments = $(this.comFormID).find('#input-com');
		this.$inputUser = $(this.comFormID).find('#input-user');
		if (this.autoInit) {
			this.init();
		}
	}

	init() {

		var self = this;

		//event submit formulaire
		$(this.comFormID).on('submit', function(e) {
			e.preventDefault();
			var data = $(this).serializeArray();
			if (self.controlComments()) {
				self.sendComments(data);
			}
		});

	}


	//controle de formulaire de commentaires
	controlComments() {
		var saisie = this.$inputComments.val();
		var user = this.$inputUser.val();
		return ($.trim(saisie) !== '' && $.trim(user) !== '' && !this.inProgress);
	}

	//ajout du commentaire dans la base
	sendComments(data) {
		this.inProgress = true;
		this.$inputComments.prop('disabled',true);
		var self = this;
		var d = Data.insertCommentaire(data);

		d.done(function(data) {
			$(document).trigger({
				type: "newComment",
				infos: data
			});

			self.$inputComments.val(_i18n[Nova.context.lang].comSend);
			setTimeout(function() {
				self.inProgress = false;
				self.$inputComments.val('');
				self.$inputComments.prop('disabled',false);
			}, 1500);


		});
	}

}