let _i18n = {
  fr: {

    comPluriel: 'commentaires'
  },
  en: {
    comPluriel: 'comments'
  }
};

export default class {
  constructor(conf) {
    let config = conf || {};
    this.autoInit = config.autoInit !== false; // le module doit-il s'auto initialiser (true)
    this.containerID = config.containerID || '#comments-container';
    this.inboxType = $(this.containerID).data('type') || null;
    this.key = $(this.containerID).data('key') || null;
    this.itemClass = config.itemClass || '.comments-item';
    this.openerBtnID = config.openerBtnID || null;
    this.tpl = _.template($('#commentTpl').text());
    this.maxHeight = config.maxHeight || null;
    this.scrollbarID = config.scrollbarID || null;
    this.isOpen = false;
    this.page=0;
    this.pageSize = 2;
    this.paddingPage=0;

    if (this.autoInit) {
      this.init();
    }
  }


  init() {
    this.cacheElements();
    this.addUIEvents();
    this.addPubSub();

  }
  init() {

    var self = this;

    //event open/close comments
    if (this.openerBtnID) {
      $(this.openerBtnID).on('click', function(e) {
        e.preventDefault();
        $(document).trigger({
          type: "resetDisplay",
          context: 'commentaires'
        });
        self.toogleContainer();
      });
    }

    //pagination

    $('#comments-more').on('click',(e)=>{
      this.onMoreComments(e);
    })


    //event de communication entre les modules
    $(document).on('resetDisplay', $.proxy(this.closeContainer, this));
    $(document).on('newComment', $.proxy(this.addNewComment, this));

    //ajustement de la scrollbar
    if (this.scrollbarID) {
      this.adjustInitHeight();
    }

  }

  //ajout d'un nouveau commentaire dans le tpl
  addNewComment(e) {
    var html = '',
      data = e.infos;


    //affichage autorisé

    if ($(this.containerID).length) {

      var html = this.tpl({
        comment: data,
        context: Nova.context
      });

      if (this.maxHeight) {
        $(this.containerID).find('.overview').append(html);
        this.updateScrollBar();

      } else {
        $(this.containerID).append(html);
      }
      this.paddingPage ++;
    }

    this.incrementNb();

  }

  //increment du nb de commentaires
  incrementNb() {
    $('.js-comments-count').text(parseInt($('.js-comments-count').eq(0).text(),10) + 1);

  }


  onMoreComments(){
      var ajax = $.get('/commentaire?id_video='+Nova.context.videoID+'&page='+ (++this.page)+'&limit='+this.pageSize+'&order=id_commentaire&tri=DESC&format=json&padding='+this.paddingPage);
      ajax.done((resp)=>{
        if(resp == null) {
          $('#comments-more').hide();
          return false;
        }
        resp.forEach((v)=>{
          var html = this.tpl({
            comment: v,
            context: Nova.context
          });

          $(this.containerID).prepend(html);
          $(document).trigger("newItems");
          if(resp.length < this.pageSize) $('#comments-more').hide()
        })
      }).fail((error)=>{
        console.error(error);
      })

  }

  //ouverture de la liste de commentaires et update de la scrollbar
  toogleContainer() {
    if ($(this.openerBtnID).find('span').text() === '0') return;
    $(this.containerID).slideToggle();
    if (this.scrollbarID) {
      $(this.scrollbarID).tinyscrollbar_update({
        sizethumb: 30
      });
    }
  }


  //ajustement de la hauteur
  adjustInitHeight() {
    if (this.maxHeight) {

      $(this.scrollbarID).find('.viewport').height(this.maxHeight);
      $(this.scrollbarID).tinyscrollbar({
        sizethumb: 30
      });

    }
  }

  //update du la scrollbar
  updateScrollBar() {
    // $(this.scrollbarID).tinyscrollbar_update({
    //  sizethumb: 30
    // });
  }

  //fermeture de la liste des commentaires
  closeContainer(e) {
    if (e.context == 'commentaires') return false;
    $(this.containerID).slideUp();
  }
}
