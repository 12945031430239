export function sendHeight() {
	
  var height;
  var target = parent.postMessage
    ? parent
    : (parent.document.postMessage
      ? parent.document
      : undefined);

  height = document.getElementById("global").scrollHeight+100;
  if (typeof target != "undefined" && height > 0) {

    var msg = {
      emitter: 'webtv',
      event: 'setHeight',
      data: {
        height: height
      }
    };

    target.postMessage(JSON.stringify(msg), "*");
  }
}

export function sendURL(data) {
console.log('sendURL');
  var target = parent.postMessage
    ? parent
    : (parent.document.postMessage
      ? parent.document
      : undefined);

  if (typeof target != "undefined") {

    var msg = {
      emitter: 'webtv',
      event: 'setURL',
      data: {
        url : data.url,
        title : data.title
      }
    };

    target.postMessage(JSON.stringify(msg), "*");
  }
}


export function sendReload(data) {
console.log('sendReload');
  var target = parent.postMessage
    ? parent
    : (parent.document.postMessage
      ? parent.document
      : undefined);

  if (typeof target != "undefined") {


    var msg = {
      emitter: 'webtv',
      event: 'reload',
      data: {}
    };

    target.postMessage(JSON.stringify(msg), "*");
  }
}

export function scrollViewToTop() {

  var target = parent.postMessage
    ? parent
    : (parent.document.postMessage
      ? parent.document
      : undefined);

  if (typeof target != "undefined") {


    var msg = {
      emitter: 'webtv',
      event: 'scrollViewToTop',
      data: {}
    };

    target.postMessage(JSON.stringify(msg), "*");
  }
}
