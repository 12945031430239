import '../vendor/foundation-sites/js/foundation.core';
import '../vendor/foundation-sites/js/foundation.util.keyboard';
import '../vendor/foundation-sites/js/foundation.util.motion';
// import '../vendor/foundation-sites/js/foundation.util.nest';
import '../vendor/foundation-sites/js/foundation.util.box';
import '../vendor/foundation-sites/js/foundation.util.triggers';
import '../vendor/foundation-sites/js/foundation.util.mediaQuery';
import '../vendor/foundation-sites/js/foundation.offcanvas';
import '../vendor/foundation-sites/js/foundation.sticky';
import '../vendor/foundation-sites/js/foundation.reveal';
// import '../vendor/foundation-sites/js/foundation.dropdown';
// import '../vendor/foundation-sites/js/foundation.accordionMenu';
import '../vendor/jquery/dist/jquery.min';

import Like from './modules/like';
import Filters from './modules/filters';
import InfiniteScroll from './modules/infinitescroll';
import FlyingPlayer from './modules/flying-player';
import CommentSender from './modules/comment-sender';
import CommentInbox from './modules/comments-inbox';
import Contact from './modules/contact';
import {sendHeight, sendURL, sendReload} from './modules/iframeMessenger';

class App {

  constructor(config) {
    this.config = config;
    this.initState = false;
    this.page = $('body').attr('data-page');
    this.routeur();
  }



  routeur() {
    this.initCommon();
    switch (this.page) {

      case 'home':
        this.initHomePage();
        break;

      case 'video':
        this.initVideoPage();
        break;

      case 'search':
        this.initSearchPage();
        break;

    }

    this.initState = !this.initState;
  }


  initCommon() {

    var self = this;

    moment.locale('fr');

    $(document).foundation();

    _.mixin({
      nl2br: function(str, is_xhtml) {
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
      }
    });

    String.prototype.striptag= function() {
        return this.replace( /<.*?>/g, '' );
      };

    _.mixin({
      truncate: function(str, length, truncateStr) {
        if (str == null) return '';
        truncateStr = truncateStr || '...';
        length = ~~length;
        return str.length > length ? str.slice(0, length) + truncateStr : str;
      }
    });





    var debounce = _.debounce(function() {
      $(document).trigger("newItems");
    }, 500);
    $(window).on('scroll resize', debounce);


    //debug mode
    $('#debug-mode').click(function() {
      $('html').toggleClass('debug');
      $('#debug').toggle();
    });

    //menu mobile
    //

    var mobileMenu = new Foundation.OffCanvas($('#offCanvas'));
    $("#menu-btn").on('click',function(){})

    $('span.menu-m__link').click(function() {
      $(this).toggleClass('menu-m__link--open')
    })

    //gestion du lazy loading d'images
    $(document).on("newItems", function() {
      self.bLazy.revalidate();
    });
    this.bLazy = new Blazy();

    //modal de contact
    this.contact = new Contact();

  }


  initHomePage() {

    //gestion du simili live avec bandeau-annonce
    let currentLiveIndex = 0;
    let countdown = 15;

    window.addEventListener("message", function(e) {
          if(e.origin.indexOf('manager.tv', 0) !== -1){
            var message = JSON.parse(e.data);
            
            //annonce changement de vidéo imminent
            if(message.emitter === "player" && message.event == "timeupdate" && livePlaylist[currentLiveIndex+1]){

                if((message.data.duration - message.data.position < countdown) && !$('#cta').hasClass('isActive')){
                  $('#cta-title').text(livePlaylist[currentLiveIndex+1].infos.name);
                  $('#cta-img').attr('src','/video-'+livePlaylist[currentLiveIndex+1].infos.id_video+'-160-90.jpg');
                  $('#cta-img').on('')
                  $('#cta').addClass('isActive');
                }else if(message.data.duration - message.data.position >= countdown){
                  $('#cta').removeClass('isActive');
                }
            }

            //changement de video
            if(message.emitter === "player" && message.event == "ended" && livePlaylist[++currentLiveIndex]){
                var req = {
                  emitter: 'novastream',
                  method: 'loadVideo',
                  params: {
                    image: '/video-'+livePlaylist[currentLiveIndex].infos.id_video+'-640-360.jpg',
          					sources: livePlaylist[currentLiveIndex].sources,
                    autoplay:true
          				}
                };
                $('#cta').removeClass('isActive');

                $('#player iframe')[0].contentWindow.postMessage(JSON.stringify(req), "*");
            }
            
            //Suppression du titre de la vidéo sur la hp
            if(message.emitter === "player" && message.event == "play"){
                $('.p-home__title').hide();
            }


          }
    });



  }

  initSearchPage(){

    if(Nova.context.query){
      this.scroll = new InfiniteScroll({
        type: "search",
        q: Nova.context.query,
        accessToken: Nova.context.accessToken,
        initialOrderBy: 'pertinence'
      });

    }else{
      this.scroll = new InfiniteScroll({
        type: "tag",
        tagID: Nova.context.tagID,
        accessToken: Nova.context.accessToken,
        initialOrderBy: 'date_creation'
      });
    }


    this.filters = new Filters();
  }


  initVideoPage() {

    this.scroll = new InfiniteScroll({
      type: "rubrique",
      id: Nova.context.rubriqueID,
      accessToken: Nova.context.accessToken,
      initialOrderBy: 'offset'
    });

    this.flyingPlayer = new FlyingPlayer();
    this.like = new Like({
			accessToken: Nova.context.accessToken
    });

    this.commentSender = new CommentSender();
		this.commentInbox = new CommentInbox();

    $(document).on("newItems", function() {
      $('.sticky').foundation('_calc', true);
    });

    $('#comment-opener').on('click',()=>{
      $('#comments-pane').slideToggle();
    })
  }

}


$(function() {
	
	sendHeight();
  setInterval(() => {
    sendHeight();
  }, 2000);

  $.ajaxSetup({
    error: function(x, status, error) {
      if (x.status == 401) {
        sendReload();
      }

    }
  });
  
  window.monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
    "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Deéembre"
  ];

  // FastClick.attach(document.body);
  window.Nova.app = new App();
});
