/* Author   : Gaetan // NOVAstream
 *  Class   : scroll infini en ajax
 *
 *  Permet de mettre en place un chargement progressif d'items
 *  un loader avec l'attribut data-type permet de definir l'url de requete ajax
 *
 *  Config du module :
 *  $loader {Jquery Object} : le spinner de chargement
 *  $wrapperlist {Jquery Object} : le wrapper d'item
 *  urls {object} : les url des requete ajax du projet par type
 *  nbItems {int} : le nb d'item à renvoyer par la requete ajax
 *  template {string} : le template d'un item
 */

var defaults = {
  $loader: $('#ajax-loader'), //id du loader
  $wrapperlist: $('#list-items'), // wrapper de liste des éléments
  nbItems: 9,
  templateID: "#rub-row-tpl"
};

export default class {

  constructor(config) {
    this.params = $.extend({}, defaults, config);
    this.paddingCall = 250; // decalage par rapport au bas de page en px déclenchant la pagination
    this.page = 1; // page en cours initialisée à 1 (2eme serie d'items)
    this.noMore = false; // flag indiquant la fin de la pagination
    this.onProgress = false; // flag indiquant q'un appel ajax est en cours
    this.type = this.params.type; // le type de données à paginer (plus récent, rubrique, recherche)
    this.orderBy = this.params.initialOrderBy;
    this.onlyUGC = false;
    this.template = _.template($(this.params.templateID).html());
    this.init();


  }

  init() {
    $('#list-items').css('height',$('#list-items').height());
    this.addUIEvents();
    this.checkPosition();
  }

  getRequestUrl(){
    switch(this.type){
      case "rubrique" :
        return  "/rubrique?tri=" + this.orderBy + "&id=" + this.params.id + "&limit="+this.params.nbItems+"&page="+this.page+"&onlyUGC="+this.onlyUGC + "&access_token="+this.params.accessToken;
      break;

      case "search" :
        return  "/search?tri=" + this.orderBy + "&q=" + encodeURI(this.params.q) + "&limit="+this.params.nbItems+"&page="+this.page+"&format=json&onlyUGC="+this.onlyUGC + "&access_token="+this.params.accessToken;
      break;

      case "tag" :
      return  "/search/tag-"+this.params.tagID+"/abc?tri=" + this.orderBy + "&limit="+this.params.nbItems+"&page="+this.page+"&format=json&onlyUGC="+this.onlyUGC + "&access_token="+this.params.accessToken;
      break;
    }
  }

  addUIEvents() {
    var self = this;
    var throttled = _.throttle(function() {
      if (!self.noMore && !self.onProgress && self.checkPosition()) {
        self.updateList();
      }

    }, 150);
    $(window).on('scroll resize', throttled);

    $.subscribe('filters:change',function(e,data){
      if( typeof data.filters.tri !== 'undefined') self.orderBy = data.filters.tri[0];
      $('#list-items').css('height',$('#list-items').height());
      self.params.$wrapperlist.empty();
      self.page = 0;
      self.onlyUGC = Boolean(data.filters.ugc)
      self.noMore = false;
      self.updateList();
    });
  }

  checkPosition() {
    if ($(window).scrollTop() > $(document).height() - $(window).height() - this.paddingCall) {
      return true;
    }
    return false;
  }

  updateList() {
    var self = this;
    this.onProgress = true;
    this.params.$loader.show();
    $.ajax({
      url: this.getRequestUrl(),
      dataType: 'json',
      success: function(data) {
        if (data && data.length > 0) {
          var html = self.template({videos:data});
          self.params.$wrapperlist.append(html);
          self.page++;
          self.onProgress = false;
          $(document).trigger("newItems");
          if(data.length < self.params.nbItems){
            self.noMore = true;
            self.params.$loader.hide();
          }

        } else {
          // console.log('plus de données à charger');
          self.noMore = true;
          self.params.$loader.hide();

        }
      }
    });
  }

}
