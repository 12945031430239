

let defaults = {
	ui: {
		moduleID: "body",
		iframe: '#player',
		mask: '#player-mask'
	}
};

export default class {

  constructor(config) {
    this.params = $.extend({}, defaults, config);
    this.init();
  }

  //mise en cache des differents elements De l'interface dans le scope de moduleID via params.ui
	cacheElements() {
		if (this.params.ui.moduleID) {
			this.$module = $(this.params.ui.moduleID);
			for (var key in this.params.ui) {
				if (this.params.ui.hasOwnProperty(key) && key !== 'moduleID') {
					this['$' + key] = this.$module.find(this.params.ui[key]);
				}
			}
		}
	}

  init() {
    this.cacheElements();
    let self = this;

    var throttled = _.throttle(()=> {

				if(Foundation.MediaQuery.atLeast('xxlarge')) {
					if (self.$mask.visible(true) && self.$iframe.hasClass('isFlying')) {
						console.log('visible');
						self.$iframe.removeClass('isFlying')
					} else if(!self.$mask.visible(true) && !self.$iframe.hasClass('isFlying')) {
						console.log('not visible');
            self.$iframe.addClass('isFlying')

					}

				}else{
					self.$iframe.removeClass('isFlying')
				}



				}, 100);

    $(window).on('scroll resize', throttled);
  }


}
