export default class {



	static getVideosFromRub(rubUrl, page) {

		return $.ajax({
			dataType: "json",
			url: '/rubrique/' + rubUrl + '?page=' + page + '&format=json'
		});

	}

	static getVideosFromWord(word, page, limit) {


		return $.ajax({
			dataType: "json",
			url: '/search/' + word + '?page=' + page + '&format=json'
		});

	}

	static getVideosFromTag(word, tag, page) {

		return $.ajax({
			dataType: "json",
			url: '/searchtag.json/' + tag + '/' + page + '/' + word
		});

	}

	static share(videoID,network,device) {

		return $.ajax({
			dataType: "json",
			url: '/share.json/' + videoID +'/'+network+'/'+device
		});

	}



	static unlike(videoID) {

		return $.ajax({
			dataType: "json",
			url: '/unlikeVideo.json/' + videoID
		});
	}

	static like(videoID) {

		return $.ajax({
			dataType: "json",
			url: '/likeVideo.json/' + videoID
		});
	}

	static insertCommentaire(data) {
  	console.log('insertCommentaire');
		return $.ajax({
			data: data,
			dataType: "json",
			type: 'post',
			url: '/commentaire/insert'
		});
	}

	static getCommentairesFromVideoId(videoID) {

		return $.ajax({
			dataType: "json",
			url: '/getCommentairesFromVideoId.json/' + videoID
		});

	}


	static sendMessage(data) {
		return $.ajax({
			data: data,
			dataType: "json",
			type: 'post',
			url: '/contact/send'
		});
	}

}
